import React from 'react';
import { useLocation } from 'react-router-dom';

const WithLocation = (Component: any) => {
	return function WrappedComponent(props: any) {
		const location = useLocation();
		return <Component {...props} location={location} />;
	};
};

export default WithLocation;
