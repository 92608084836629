import React from 'react';
import './contentFour.css';
import {Box, Button} from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {Autoplay, Navigation, Pagination} from "swiper/modules";
import { Swiper, SwiperSlide } from 'swiper/react';
import nylonTop from '../../../../assets/images/nylon-top.jpg';
import carbonTop from '../../../../assets/images/carbon-top.jpg';
import CopperTop from '../../../../assets/images/copper-top.jpg';
import nylonPalm from '../../../../assets/images/nylon-palm.jpg';
import carbonPalm from '../../../../assets/images/carbon-palm.jpg';

interface Props {}
interface State {
	isDetailVisible: number;
}

interface IGloveData {
	id: number;
	title: string;
	img: string;
	description: string;
}

const gloveData: IGloveData[] = [
	{
		id: 0,
		title: 'Nylon Top',
		img: nylonTop,
		description: 'Nylon Top'
	},
	{
		id: 1,
		title: 'Carbon Top',
		img: carbonTop,
		description: 'Carbon Top'
	},
	{
		id: 2,
		title: 'Antistatic Top',
		img: CopperTop,
		description: 'Antistatic Top'
	},
	{
		id: 3,
		title: 'Nylon Palm',
		img: nylonPalm,
		description: 'Nylon Palm'
	},
	{
		id: 4,
		title: 'Carbon Palm',
		img: carbonPalm,
		description: 'Carbon Palm'
	}
]

class ContentFour extends React.PureComponent<Props, State> {
	state: State = {
		isDetailVisible: -1,
	}

	private handleMouseEnter = (id: number) => {
		this.setState({isDetailVisible: id});
	};

	private handleMouseLeave = () => {
		this.setState({isDetailVisible: -1});
	};

	renderGloveImage = (data: IGloveData[]) => {
		const {isDetailVisible} = this.state;
		return data.map((i) => {
			return (
				<SwiperSlide
					key={i.id}
					className={'image'}
					onMouseEnter={() => {this.handleMouseEnter(i.id)}}
					onMouseLeave={this.handleMouseLeave}
				>
					<Box className={`title ${isDetailVisible === i.id ? 'visible' : null}`}>{i.title}</Box>
					<Box sx={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
						<img src={i.img} alt="" height={'80%'} width={'80%'} />
					</Box>
					{/*<Box className={`description ${isDetailVisible === i.id ? 'visible' : null}`}>*/}
					{/*	<Box className={'wrapper'}>*/}
					{/*		{i.description}*/}
					{/*	</Box>*/}
					{/*</Box>*/}

				</SwiperSlide>
			)
		})
	};

	render() {
		return (
			<Box className={'content-four-container'}>
				<Box className={'header'}>
					<Box className={'left-section'}>
						<Box className={'title'}>Main Products</Box>
						<Box className={'divider'} />
						<Box className={'description'}>
							Introduce the industrial safety glove products of Samwha Clean Glove
						</Box>
					</Box>
					<Box className={'right-section'}>
						<Box className={'btn-container'}>
							<Button variant={'outlined'} className={'prev-btn btn'} color={'warning'}>
								<KeyboardArrowLeftIcon />
							</Button>
							<Box sx={{margin: '0 4px'}} />
							<Button variant={'outlined'} className={'next-btn btn'} color={'warning'}>
								<KeyboardArrowRightIcon />
							</Button>
						</Box>
					</Box>
				</Box>

				<Swiper
					className={'swiper'}
					slidesPerView={3}
					pagination={{clickable: true}}
					navigation={{
						prevEl: '.prev-btn',
						nextEl: '.next-btn'
					}}
					loop={true}
					modules={[Autoplay, Pagination, Navigation]}
					breakpoints={{
						230: {
							slidesPerView: 1,
						},
						640: {
							slidesPerView: 1,
						},
						768: {
							slidesPerView: 2,
						},
						1024: {
							slidesPerView: 3,
						},
						1920: {
							slidesPerView: 4,
						},
					}}
				>
					{this.renderGloveImage(gloveData)}
				</Swiper>

			</Box>
		);
	}
}

export default ContentFour;
