import React from 'react';
import './nylon.css';
import {Box} from "@mui/material";
import ProductList from "../../product/list/ProductList";
import ProductHeader from "../../product/header/ProductHeader";
import WithLocation from "../../common/WithLocation";
import {gloveData} from "../../../database/gloveData";

interface Props {
	location: any;
}
interface State {}

class Nylon extends React.PureComponent<Props, State> {

	render() {
		const {location} = this.props;
		const filteredData = gloveData.filter((i) => i.id === this.props.location.state.id)
		const filteredProduct = filteredData[0].product
		return (
			<Box className={'nylon-container'}>
				<ProductHeader category={location.state.category} />
				<ProductList filteredData={filteredProduct} />
			</Box>
		);
	}
}

export default WithLocation(Nylon);
