import React from 'react';
import './contentOne.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import {Autoplay, Navigation, Pagination} from "swiper/modules";
import testImage1 from "../../../../assets/images/test-image1.jpg";
import testImage2 from "../../../../assets/images/test-image2.jpg";
import {Box, Button} from "@mui/material";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

interface Props {}
interface State {
	currentImageId: number;
}

interface IProcedureData {
	id: number;
	title: string;
	img: string;
	description: string;
}

const procedureData: IProcedureData[] = [
	{
		id: 0,
		title: 'Knitting',
		img: testImage1,
		description: 'Depending on the type of yarn and size, we use 13G, 15G, and 18G machines to knit gloves that perfectly fit your hands.'
	},
	{
		id: 1,
		title: 'Overlock / Serger',
		img: testImage2,
		description: 'After the knitting process, we offer overlock stitching in various colors to prevent fraying and ensure a high-quality finish.'
	},
	{
		id: 2,
		title: 'Top Coating',
		img: testImage1,
		description: 'The PU coating on the fingertips prevents slippage and provides excellent breathability and superior comfort.'
	},
	{
		id: 3,
		title: 'Palm Coating',
		img: testImage2,
		description: 'The full PU coating on the palm ensures superior breathability and provides excellent protection for your hands during various tasks.'
	},
	{
		id: 4,
		title: 'Printing',
		img: testImage1,
		description: 'We offer customization options, including various colors and designs, to add logos that make your gloves stand out from the rest.'
	},
]

class ContentOne extends React.PureComponent<Props, State> {
	state: State = {
		currentImageId: -1,
	};

	renderImage = (data: IProcedureData[]) => {
		return data.map((i) => {
			return (
				<SwiperSlide className={'image'} key={i.id}>
					<img src={i.img} alt="" />
				</SwiperSlide>
			)
		})
	};

	renderText = (data: IProcedureData[]) => {
		const {currentImageId} = this.state;
		return data.map((i) => {
			return (
				<>
					<Box className={'title'}>{currentImageId === i.id && i.title}</Box>
					<Box className={'description'}>
						{currentImageId === i.id && i.description}
					</Box>
				</>
			)
		})
	};

	render() {
		return (
			<Box className={'content-one-container'}>
				<Swiper
					className={'mySwiper'}
					spaceBetween={50}
					slidesPerView={1}
					autoplay={{delay: 4000, disableOnInteraction: false}}
					pagination={{clickable: true}}
					navigation={{
						prevEl: '.prev-btn',
						nextEl: '.next-btn'
					}}
					loop={true}
					modules={[Autoplay, Pagination, Navigation]}
					onSlideChange={(swiper: any) => {
						this.setState({currentImageId: swiper.realIndex})
					}}
				>
					{this.renderImage(procedureData)}
				</Swiper>

				<Box className={'detail-container'}>
					<Box className={'theme'}>PRODUCTION PROCEDURE</Box>

					<Box className={'title'}>{procedureData[this.state.currentImageId]?.title}</Box>
					<Box className={'description'}>
						{procedureData[this.state.currentImageId]?.description}
					</Box>

					<Box className={'btn-container'}>
						<Button variant={'contained'} className={'prev-btn btn'}>
							<KeyboardArrowLeftIcon />
						</Button>
						<Button variant={'contained'} className={'next-btn btn'}>
							<KeyboardArrowRightIcon />
						</Button>
					</Box>
				</Box>
			</Box>
		);
	}
}

export default ContentOne;
