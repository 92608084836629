import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "../screens/home/Home";
import MainContainer from "../components/base/container/MainContainer";
import Products from "../screens/products/Products";
import Nylon from "../components/screens/nylon/Nylon";
import Product from "../screens/product/Product";
import Contact from "../components/screens/contact/Contact";

interface Props {}
interface State {}

class Router extends React.Component<Props, State> {
	render() {
		return (
			<BrowserRouter>
				<Routes>
					{/* HOME */}
					<Route
						index
						element={
							<MainContainer>
								<Home />
							</MainContainer>
						}
					/>

					{/* PRODUCTS */}
					<Route
						path={'/product'}
						element={
							<MainContainer>
								<Products />
							</MainContainer>
						}
					/>

					{/* Nylon */}
					<Route
						path={'/product/:id'}
						element={
							<MainContainer>
								<Nylon />
							</MainContainer>
						}
					/>

					{/* Product */}
					<Route
						path={'/product/item/:id'}
						element={
							<MainContainer>
								<Product />
							</MainContainer>
						}
					/>

					{/* Contact */}
					<Route
						path={'/contact'}
						element={
							<MainContainer>
								<Contact />
							</MainContainer>
						}
					/>
				</Routes>
			</BrowserRouter>
		);
	}
}

export default Router;
