import React from 'react';
import './home.css';
import {Box} from "@mui/material";
import ContentOne from "../../components/screens/home/contentOne/ContentOne";
import ContentTwo from "../../components/screens/home/contentTwo/ContentTwo";
import ContentThree from "../../components/screens/home/contentThree/ContentThree";
import ContentFour from "../../components/screens/home/contentFour/ContentFour";
import ContentFive from "../../components/screens/home/contentFive/ContentFive";

interface Props {}
interface State {}

class Home extends React.PureComponent<Props, State> {

	render() {
		return (
			<Box className={'home-container'}>
				<ContentOne />
				<ContentTwo />
				<ContentThree />
				<ContentFour />
				<ContentFive />
			</Box>
		)
	}
}

export default Home;
