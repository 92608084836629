import React from 'react';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface State {}

export default class TabPanel extends React.Component<TabPanelProps, State> {
  render() {
    const { children, value, index, ...other } = this.props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        style={{ height: '100%' }}
        {...other}
      >
        {value === index && <div style={{ height: '100%' }}>{children}</div>}
      </div>
    );
  }
}
