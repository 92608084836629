import React from 'react';
import './header.css';
import {
	AppBar,
	Box,
	Button,
	Container, Divider,
	Drawer,
	IconButton,
	List,
	ListItem, ListItemButton, ListItemIcon, ListItemText,
	Menu,
	MenuItem,
	Toolbar
} from "@mui/material";
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import LOGO from '../../../assets/images/SAMWHA_logo_03.gif'
import MenuIcon from '@mui/icons-material/Menu';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SignLanguageOutlinedIcon from '@mui/icons-material/SignLanguageOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {NavLink} from "react-router-dom";

interface Props {
	scrollY: number;
}
interface State {
	anchorEl: HTMLElement | null;
	headerColor: 'transparent' | 'inherit' | 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
	windowWidth: number,
	scrollY: number,
	isSideMenuOpen: boolean;
}

const SCROLL_Y_STANDARD = 100;

class Header extends React.PureComponent<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			anchorEl: null,
			headerColor: "transparent", // 초기 색상
			windowWidth: window.innerWidth,
			scrollY: 0,
			isSideMenuOpen: false,
		};
	}

	componentDidMount() {
		window.addEventListener('resize', this.handleResize);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize);
	}

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
		if (this.props.scrollY !== prevProps.scrollY) {
			this.setState({scrollY: this.props.scrollY}, () => {
				if (this.props.scrollY > SCROLL_Y_STANDARD) {
					this.setState({headerColor: 'default'})
				} else {
					this.setState({headerColor: "transparent"})
				}
			})
		}
	}

	private handleResize = () => {
		this.setState({windowWidth: window.innerWidth}, () => {});
	}

	private handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		this.setState({ anchorEl: event.currentTarget });
	}

	private handleClose = () => {
		this.setState({ anchorEl: null });
	}

	private toggleMenu = () => {
		this.setState(prevState => ({
			isSideMenuOpen: !prevState.isSideMenuOpen
		}));
	};

	renderSideMenu = () => {
		const renderListItem = (icon: React.ReactNode, title: string, to: string) => {
			return (
				<ListItem disablePadding>
					<ListItemButton component={NavLink} to={to} onClick={this.toggleMenu}>
						<ListItemIcon sx={{color: '#ed6c02'}}>
							{icon}
						</ListItemIcon>
						<ListItemText primary={title} />
					</ListItemButton>
				</ListItem>
			)
		}
		return (
			<Box className={'side-menu-container'} sx={{width: '250px'}}>
				<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
					<img className={'logo'} src={LOGO} alt={''} sizes={'contain'} width={'80%'} style={{padding: '30px'}} />
				</Box>
				<List>
					{renderListItem(<HomeOutlinedIcon />, 'HOME', '/')}
					{renderListItem(<SignLanguageOutlinedIcon />, 'PRODUCTS', '/product')}
					{renderListItem(<InfoOutlinedIcon />, 'CONTACT', '/contact')}
				</List>
				<Divider />
			</Box>
		)
	}

	render() {
		const { anchorEl, headerColor, windowWidth, isSideMenuOpen, scrollY } = this.state;
		const open = Boolean(anchorEl);

		return (
				windowWidth > 600 ? (
				<AppBar
					className={'main-header'}
					color={headerColor}
					sx={{ transition: 'background-color 0.6s ease' }}
					onMouseEnter={() => {
						if (scrollY < SCROLL_Y_STANDARD) {
							this.setState({headerColor: 'default'})
						}
					}}
					onMouseLeave={() => {
						if (scrollY < SCROLL_Y_STANDARD) {
							this.setState({headerColor: 'transparent'})
						}
					}}
				>
					<Container maxWidth={false} disableGutters className={'container'}>
						<Toolbar disableGutters className={'toolbar'}>
							<Box className={'left-section'}>
								<Box component={NavLink} to={'/'} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '20px' }}>
									<img className={'logo'} src={LOGO} alt={''} sizes={'cover'} />
								</Box>

								<Box className={'menu-container'}>
									<Button component={NavLink} to={'/'} className={'button'}>HOME</Button>
									<Button component={NavLink} to={'/product'} className={'button'}>PRODUCTS</Button>
									<Button component={NavLink} to={'/contact'} className={'button'}>CONTACT</Button>
								</Box>
							</Box>

							<Box className={'language'}>
								<Button
									className={'button'}
									id="basic-button"
									aria-controls={open ? 'basic-menu' : undefined}
									aria-haspopup="true"
									aria-expanded={open ? 'true' : undefined}
									onClick={this.handleClick}
									endIcon={<KeyboardArrowDownOutlinedIcon />}
								>
									ENG
								</Button>
								<Menu
									className={'menu'}
									id="basic-menu"
									anchorEl={anchorEl}
									open={open}
									onClose={this.handleClose}
									MenuListProps={{
										'aria-labelledby': 'basic-button',
									}}
								>
									<MenuItem
										className={'item'}
										onClick={this.handleClose}
										selected
										divider
									>
										ENG
									</MenuItem>
									<MenuItem className={'item'} onClick={this.handleClose}>KOR</MenuItem>
								</Menu>
							</Box>
						</Toolbar>
					</Container>
				</AppBar>
				) : (
					<Box className={'mobile-menu-container'}>
						<IconButton className={'menu-btn'} onClick={this.toggleMenu}>
							<MenuIcon className={'menu-icon'} color={'warning'} />
						</IconButton>
						<Drawer anchor={'left'} className={'drawer'} open={isSideMenuOpen} onClose={this.toggleMenu}>
							{this.renderSideMenu()}
						</Drawer>
					</Box>
				)
		);
	}
}

export default Header;
