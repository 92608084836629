import PU_PALM_IMG from '../assets/images/pu-palm.jpg';
import CUT_GLOVE_IMG from '../assets/images/cut-glove.jpg';
import NBR_GLOVE_IMG from '../assets/images/nbr-glove.jpg';
import CARBON_TOP_IMG from '../assets/images/carbon-top.jpg';
import CARBON_INNER_IMG from '../assets/images/carbon-inner.jpg';
import CARBON_PALM_IMG from '../assets/images/carbon-palm.jpg';
import CARBON_DOT_TOP_IMG from '../assets/images/carbon-dot-top.jpg';
import COPPER_TOP_IMG from '../assets/images/copper-top.jpg';
import COPPER_PALM_IMG from '../assets/images/copper-palm.jpg';
import NYLON_PALM_IMG from '../assets/images/nylon-palm.jpg';
import NYLON_DOT_IMG from '../assets/images/nylon-dot.jpg';
import NYLON_INNER_IMG from '../assets/images/nylon-inner.jpg';
import NYLON_TOP_IMG from '../assets/images/nylon-top.jpg';
import GRAY_NYLON_TOP_IMG from '../assets/images/gray-nylon-top.jpg';


export interface IGloveData {
	id: number;
	category: string;
	product: {
		id: number,
		gloveName: string,
		shortDescription: string,
		mainDescription: string,
		overlock: boolean,
		size: {
			id: number,
			name: string,
			number: string,
			length: number,
			rubberYarnColor: string,
			rubberYarnColorName: string,
			overlockColor: string,
			overlockColorName: string
		}
	}
}

const nylonDescription =
					`
						<h2 style="font-size: 16px">High-Elasticity Nylon Gloves</h2>
						<ul>
							<li style="margin-bottom: 10px">Designed to protect hands and prevent slipping with a soft polyurethane coating on high-elasticity nylon yarn.</li>
							<li style="margin-bottom: 10px">Highly breathable, dust-free, and fingerprint-resistant, making them ideal for cleanroom environments.</li>
							<li style="margin-bottom: 10px">Versatile gloves suitable for a wide range of applications, from household tasks to industrial settings.</li>
							<li style="margin-bottom: 10px">Exceptional elasticity recovery rate ensures durability and long-lasting performance.</li>
						</ul>
					`

const carbonDescription =
					`
						<h2 style="font-size: 16px">High-Elasticity Carbon Gloves</h2>
						<ul>
							<li style="margin-bottom: 10px">High-strength composite material made from carbon fiber</li>
							<li style="margin-bottom: 10px">Excellent resistance to high temperatures and chemicals, with minimal thermal expansion</li>
							<li style="margin-bottom: 10px">Good breathability, preventing dust and protecting products from fingerprints</li>
							<li style="margin-bottom: 10px">Used in cleanroom and semiconductor operations to prevent contamination from workers</li>
							<li style="margin-bottom: 10px">Gloves that protect both the wearer and products from static electricity</li>
						</ul>
					`

const copperDescription =
	`
						<h2 style="font-size: 16px">High-Elasticity Copper Gloves</h2>
						<ul>
							<li style="margin-bottom: 10px">Copper-coated conductive yarn requires the highest level of technology among conductive fibers and is an advanced material with exceptional properties.</li>
							<li style="margin-bottom: 10px">Exhibits antimicrobial, deodorizing effects, and emits far-infrared radiation, making it suitable for a variety of products.</li>
							<li style="margin-bottom: 10px">Copper fiber has antibacterial, antiviral, and antimicrobial properties to prevent various infections.</li>
							<li style="margin-bottom: 10px">Offers good breathability, preventing dust and protecting products from fingerprints.</li>
							<li style="margin-bottom: 10px">Used in cleanroom and semiconductor operations to prevent contamination from workers</li>
							<li style="margin-bottom: 10px">Gloves that protect both the wearer and products from static electricity</li>
						</ul>
					`

export const gloveData = [
	{
		id: 'nylon',
		category: 'Nylon/Poly',
		enable: true,
		product: [
			{
				id: 0,
				gloveName: 'Nylon Palm',
				image: NYLON_PALM_IMG,
				imageList: [
					NYLON_PALM_IMG
				],
				shortDescription: '',
				mainDescription: nylonDescription,
				overlock: false,
				technicalData: {
					material: 'Nylon',
					knitting: '13 Gauge / 15 Gauge',
					coating: 'PU PALM',
					origin: 'South Korea',
				},
				size: [
					{
						id: 0,
						name: 'xs',
						number: '6',
						length: 18,
						rubberYarnColor: '#54b3d0',
						rubberYarnColorName: 'skyblue',
					},
					{
						id: 1,
						name: 's',
						number: '7',
						length: 19,
						rubberYarnColor: '#cccccc',
						rubberYarnColorName: 'white',
					},
					{
						id: 2,
						name: 'm',
						number: '8',
						length: 20,
						rubberYarnColor: '#53b73c',
						rubberYarnColorName: 'green',
					},
					{
						id: 3,
						name: 'l',
						number: '9',
						length: 20.5,
						rubberYarnColor: '#727272',
						rubberYarnColorName: 'gray',
					},
					{
						id: 4,
						name: 'xl',
						number: '10',
						length: 21.5,
						rubberYarnColor: '#193cb4',
						rubberYarnColorName: 'blue',
					},
				]
			},
			{
				id: 1,
				gloveName: 'Nylon Top',
				image: NYLON_TOP_IMG,
				imageList: [
					NYLON_TOP_IMG
				],
				shortDescription: '',
				mainDescription: nylonDescription,
				overlock: false,
				technicalData: {
					material: 'Nylon',
					knitting: '13 Gauge / 15 Gauge',
					coating: 'PU TOP',
					origin: 'South Korea',
				},
				size: [
					{
						id: 0,
						name: 'xs',
						number: '6',
						length: 18,
						rubberYarnColor: '#54b3d0',
						rubberYarnColorName: 'skyblue',
					},
					{
						id: 1,
						name: 's',
						number: '7',
						length: 19,
						rubberYarnColor: '#cccccc',
						rubberYarnColorName: 'white',
					},
					{
						id: 2,
						name: 'm',
						number: '8',
						length: 20,
						rubberYarnColor: '#53b73c',
						rubberYarnColorName: 'green',
					},
					{
						id: 3,
						name: 'l',
						number: '9',
						length: 20.5,
						rubberYarnColor: '#727272',
						rubberYarnColorName: 'gray',
					},
					{
						id: 4,
						name: 'xl',
						number: '10',
						length: 21.5,
						rubberYarnColor: '#193cb4',
						rubberYarnColorName: 'blue',
					},
				]
			},
			{
				id: 2,
				gloveName: 'Nylon Dot',
				image: NYLON_DOT_IMG,
				imageList: [
					NYLON_DOT_IMG
				],
				shortDescription: '',
				mainDescription: nylonDescription,
				overlock: false,
				technicalData: {
					material: 'Nylon',
					knitting: '13 Gauge / 15 Gauge',
					coating: '-',
					origin: 'South Korea',
				},
				size: [
					{
						id: 0,
						name: 'xs',
						number: '6',
						length: 18,
						rubberYarnColor: '#54b3d0',
						rubberYarnColorName: 'skyblue',
					},
					{
						id: 1,
						name: 's',
						number: '7',
						length: 19,
						rubberYarnColor: '#cccccc',
						rubberYarnColorName: 'white',
					},
					{
						id: 2,
						name: 'm',
						number: '8',
						length: 20,
						rubberYarnColor: '#53b73c',
						rubberYarnColorName: 'green',
					},
					{
						id: 3,
						name: 'l',
						number: '9',
						length: 20.5,
						rubberYarnColor: '#727272',
						rubberYarnColorName: 'gray',
					},
					{
						id: 4,
						name: 'xl',
						number: '10',
						length: 21.5,
						rubberYarnColor: '#193cb4',
						rubberYarnColorName: 'blue',
					},
				]
			},
			{
				id: 3,
				gloveName: 'Nylon Liner',
				image: NYLON_INNER_IMG,
				imageList: [
					NYLON_INNER_IMG
				],
				shortDescription: '',
				mainDescription: nylonDescription,
				overlock: false,
				technicalData: {
					material: 'Nylon',
					knitting: '13 Gauge / 15 Gauge',
					coating: '-',
					origin: 'South Korea',
				},
				size: [
					{
						id: 0,
						name: 'xs',
						number: '6',
						length: 18,
						rubberYarnColor: '#54b3d0',
						rubberYarnColorName: 'skyblue',
					},
					{
						id: 1,
						name: 's',
						number: '7',
						length: 19,
						rubberYarnColor: '#cccccc',
						rubberYarnColorName: 'white',
					},
					{
						id: 2,
						name: 'm',
						number: '8',
						length: 20,
						rubberYarnColor: '#53b73c',
						rubberYarnColorName: 'green',
					},
					{
						id: 3,
						name: 'l',
						number: '9',
						length: 20.5,
						rubberYarnColor: '#727272',
						rubberYarnColorName: 'gray',
					},
					{
						id: 4,
						name: 'xl',
						number: '10',
						length: 21.5,
						rubberYarnColor: '#193cb4',
						rubberYarnColorName: 'blue',
					},
				]
			},
			{
				id: 4,
				gloveName: 'Gray Nylon Top',
				image: GRAY_NYLON_TOP_IMG,
				imageList: [
					GRAY_NYLON_TOP_IMG
				],
				shortDescription: '',
				mainDescription: nylonDescription,
				overlock: false,
				technicalData: {
					material: 'Nylon',
					knitting: '13 Gauge / 15 Gauge',
					coating: 'TOP',
					origin: 'South Korea',
				},
				size: [
					{
						id: 0,
						name: 'xs',
						number: '6',
						length: 18,
						rubberYarnColor: '#54b3d0',
						rubberYarnColorName: 'skyblue',
					},
					{
						id: 1,
						name: 's',
						number: '7',
						length: 19,
						rubberYarnColor: '#cccccc',
						rubberYarnColorName: 'white',
					},
					{
						id: 2,
						name: 'm',
						number: '8',
						length: 20,
						rubberYarnColor: '#53b73c',
						rubberYarnColorName: 'green',
					},
					{
						id: 3,
						name: 'l',
						number: '9',
						length: 20.5,
						rubberYarnColor: '#727272',
						rubberYarnColorName: 'gray',
					},
					{
						id: 4,
						name: 'xl',
						number: '10',
						length: 21.5,
						rubberYarnColor: '#193cb4',
						rubberYarnColorName: 'blue',
					},
				]
			},
		]
	},

	{
		id: 'carbon',
		category: 'Carbon',
		enable: true,
		product: [
			{
				id: 0,
				gloveName: 'Carbon Top',
				image: CARBON_TOP_IMG,
				imageList: [
					CARBON_TOP_IMG
				],
				shortDescription: '',
				mainDescription: carbonDescription,
				overlock: false,
				technicalData: {
					material: 'Carbon',
					knitting: '13 Gauge / 15 Gauge',
					coating: 'PU TOP',
					origin: 'South Korea',
				},
				size: [
					{
						id: 0,
						name: 'xs',
						number: '6',
						length: 18,
						rubberYarnColor: '#54b3d0',
						rubberYarnColorName: 'skyblue',
					},
					{
						id: 1,
						name: 's',
						number: '7',
						length: 19,
						rubberYarnColor: '#cccccc',
						rubberYarnColorName: 'white',
					},
					{
						id: 2,
						name: 'm',
						number: '8',
						length: 20,
						rubberYarnColor: '#53b73c',
						rubberYarnColorName: 'green',
					},
					{
						id: 3,
						name: 'l',
						number: '9',
						length: 20.5,
						rubberYarnColor: '#727272',
						rubberYarnColorName: 'gray',
					},
					{
						id: 4,
						name: 'xl',
						number: '10',
						length: 21.5,
						rubberYarnColor: '#193cb4',
						rubberYarnColorName: 'blue',
					},
				]
			},
			{
				id: 1,
				gloveName: 'Carbon Liner',
				image: CARBON_INNER_IMG,
				imageList: [
					CARBON_INNER_IMG
				],
				shortDescription: '',
				mainDescription: carbonDescription,
				overlock: false,
				technicalData: {
					material: 'Carbon',
					knitting: '13 Gauge / 15 Gauge',
					coating: '-',
					origin: 'South Korea',
				},
				size: [
					{
						id: 0,
						name: 'xs',
						number: '6',
						length: 18,
						rubberYarnColor: '#54b3d0',
						rubberYarnColorName: 'skyblue',
					},
					{
						id: 1,
						name: 's',
						number: '7',
						length: 19,
						rubberYarnColor: '#cccccc',
						rubberYarnColorName: 'white',
					},
					{
						id: 2,
						name: 'm',
						number: '8',
						length: 20,
						rubberYarnColor: '#53b73c',
						rubberYarnColorName: 'green',
					},
					{
						id: 3,
						name: 'l',
						number: '9',
						length: 20.5,
						rubberYarnColor: '#727272',
						rubberYarnColorName: 'gray',
					},
					{
						id: 4,
						name: 'xl',
						number: '10',
						length: 21.5,
						rubberYarnColor: '#193cb4',
						rubberYarnColorName: 'blue',
					},
				]
			},
			{
				id: 2,
				gloveName: 'Carbon Palm',
				image: CARBON_PALM_IMG,
				imageList: [
					CARBON_PALM_IMG
				],
				shortDescription: '',
				mainDescription: carbonDescription,
				overlock: false,
				technicalData: {
					material: 'Carbon',
					knitting: '13 Gauge / 15 Gauge',
					coating: 'PU PALM',
					origin: 'South Korea',
				},
				size: [
					{
						id: 0,
						name: 'xs',
						number: '6',
						length: 18,
						rubberYarnColor: '#54b3d0',
						rubberYarnColorName: 'skyblue',
					},
					{
						id: 1,
						name: 's',
						number: '7',
						length: 19,
						rubberYarnColor: '#cccccc',
						rubberYarnColorName: 'white',
					},
					{
						id: 2,
						name: 'm',
						number: '8',
						length: 20,
						rubberYarnColor: '#53b73c',
						rubberYarnColorName: 'green',
					},
					{
						id: 3,
						name: 'l',
						number: '9',
						length: 20.5,
						rubberYarnColor: '#727272',
						rubberYarnColorName: 'gray',
					},
					{
						id: 4,
						name: 'xl',
						number: '10',
						length: 21.5,
						rubberYarnColor: '#193cb4',
						rubberYarnColorName: 'blue',
					},
				]
			},
			{
				id: 3,
				gloveName: 'Carbon Dot Top',
				image: CARBON_DOT_TOP_IMG,
				imageList: [
					CARBON_DOT_TOP_IMG
				],
				shortDescription: '',
				mainDescription: carbonDescription,
				overlock: false,
				technicalData: {
					material: 'Carbon',
					knitting: '13 Gauge / 15 Gauge',
					coating: 'PU TOP',
					origin: 'South Korea',
				},
				size: [
					{
						id: 0,
						name: 'xs',
						number: '6',
						length: 18,
						rubberYarnColor: '#54b3d0',
						rubberYarnColorName: 'skyblue',
					},
					{
						id: 1,
						name: 's',
						number: '7',
						length: 19,
						rubberYarnColor: '#cccccc',
						rubberYarnColorName: 'white',
					},
					{
						id: 2,
						name: 'm',
						number: '8',
						length: 20,
						rubberYarnColor: '#53b73c',
						rubberYarnColorName: 'green',
					},
					{
						id: 3,
						name: 'l',
						number: '9',
						length: 20.5,
						rubberYarnColor: '#727272',
						rubberYarnColorName: 'gray',
					},
					{
						id: 4,
						name: 'xl',
						number: '10',
						length: 21.5,
						rubberYarnColor: '#193cb4',
						rubberYarnColorName: 'blue',
					},
				]
			}
		]
	},
	{
		id: 'copper',
		category: 'Copper',
		enable: true,
		product: [
			{
				id: 0,
				gloveName: 'Copper Top',
				image: COPPER_TOP_IMG,
				imageList: [
					COPPER_TOP_IMG
				],
				shortDescription: '',
				mainDescription: copperDescription,
				overlock: true,
				technicalData: {
					material: 'Copper',
					knitting: '13 Gauge / 15 Gauge',
					coating: 'PU TOP',
					origin: 'South Korea',
				},
				size: [
					{
						id: 0,
						name: 'xs',
						number: '6',
						length: 18,
						rubberYarnColor: '#54b3d0',
						rubberYarnColorName: 'skyblue',
					},
					{
						id: 1,
						name: 's',
						number: '7',
						length: 19,
						rubberYarnColor: '#cccccc',
						rubberYarnColorName: 'white',
					},
					{
						id: 2,
						name: 'm',
						number: '8',
						length: 20,
						rubberYarnColor: '#53b73c',
						rubberYarnColorName: 'green',
					},
					{
						id: 3,
						name: 'l',
						number: '9',
						length: 20.5,
						rubberYarnColor: '#727272',
						rubberYarnColorName: 'gray',
					},
					{
						id: 4,
						name: 'xl',
						number: '10',
						length: 21.5,
						rubberYarnColor: '#193cb4',
						rubberYarnColorName: 'blue',
					},
				]
			},
			{
				id: 0,
				gloveName: 'Copper Palm',
				image: COPPER_PALM_IMG,
				imageList: [
					COPPER_PALM_IMG
				],
				shortDescription: '',
				mainDescription: copperDescription,
				overlock: true,
				technicalData: {
					material: 'Copper',
					knitting: '13 Gauge / 15 Gauge',
					coating: 'PU Palm',
					origin: 'South Korea',
				},
				size: [
					{
						id: 0,
						name: 'xs',
						number: '6',
						length: 18,
						rubberYarnColor: '#54b3d0',
						rubberYarnColorName: 'skyblue',
					},
					{
						id: 1,
						name: 's',
						number: '7',
						length: 19,
						rubberYarnColor: '#cccccc',
						rubberYarnColorName: 'white',
					},
					{
						id: 2,
						name: 'm',
						number: '8',
						length: 20,
						rubberYarnColor: '#53b73c',
						rubberYarnColorName: 'green',
					},
					{
						id: 3,
						name: 'l',
						number: '9',
						length: 20.5,
						rubberYarnColor: '#727272',
						rubberYarnColorName: 'gray',
					},
					{
						id: 4,
						name: 'xl',
						number: '10',
						length: 21.5,
						rubberYarnColor: '#193cb4',
						rubberYarnColorName: 'blue',
					},
				]
			}
		]
	},
	{
		id: 'hppe',
		category: 'HPPE',
		enable: false,
		product: [
			{
				id: 0,
				gloveName: 'SC-200',
				image: PU_PALM_IMG,
				imageList: [
					PU_PALM_IMG,
					CUT_GLOVE_IMG,
					NBR_GLOVE_IMG,
					CARBON_TOP_IMG
				],
				shortDescription: '',
				mainDescription: 'Water-based polyurethane coating technology makes gloves safe to handle food by eliminating harmful materials. Furthermore, micro cell coating keeps gloves waterproof and liquid-proof. Coating remains breathable and offers gloves to your workforce encourages them to protect their hands, as the gloves can be worn comfortably all day\n',
				overlock: true,
				technicalData: {
					material: 'Carbon / Nylon',
					knitting: '13 Gauge',
					coating: 'PU PALM',
					origin: 'South Korea',
				},
				size: [
					{
						id: 0,
						name: 'xs',
						number: '6',
						length: 23,
						rubberYarnColor: '#54b3d0',
						rubberYarnColorName: 'skyblue',
						overlockColor: '#763b9b',
						overlockColorName: 'purple',
					},
					{
						id: 1,
						name: 's',
						number: '7',
						length: 24,
						rubberYarnColor: '#cccccc',
						rubberYarnColorName: 'white',
						overlockColor: '#cccccc',
						overlockColorName: 'white',
					},
					{
						id: 2,
						name: 'm',
						number: '8',
						length: 25,
						rubberYarnColor: '#cccccc',
						rubberYarnColorName: 'white',
						overlockColor: '#cccccc',
						overlockColorName: 'white',
					},
					{
						id: 3,
						name: 'l',
						number: '9',
						length: 25,
						rubberYarnColor: '#cccccc',
						rubberYarnColorName: 'white',
						overlockColor: '#cccccc',
						overlockColorName: 'white',
					},
					{
						id: 4,
						name: 'xl',
						number: '10',
						length: 26,
						rubberYarnColor: '#cccccc',
						rubberYarnColorName: 'white',
						overlockColor: '#cccccc',
						overlockColorName: 'white',
					},
					{
						id: 5,
						name: 'xxl',
						number: '11',
						length: 27,
						rubberYarnColor: '#cccccc',
						rubberYarnColorName: 'white',
						overlockColor: '#cccccc',
						overlockColorName: 'white',
					},
				]
			}
		]
	},
]
