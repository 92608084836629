import React from 'react';
import './contentThree.css';
import {Box} from "@mui/material";
import CountUp from "react-countup";

interface Props {}
interface State {}

class ContentThree extends React.PureComponent<Props, State> {
	render() {
		return (
			<Box sx={{}} className={'content-three-container'}>
				<Box className={'left-section'}>
					<Box className={'title'}>
						<Box>We are</Box>
						<Box style={{fontWeight: 'bold', fontSize: '55px'}}>SAMWHA</Box>
						<Box style={{fontSize: '32px', marginTop: '-10px', color: '#666666'}}>CLEAN GLOVE</Box>
					</Box>

					<Box className={'divider'}></Box>

					<Box className={'description'}>
						Since its establishment, Samhwa Clean Glove has equipped itself with PU coating glove knitting machines and PU coating facilities to produce safer and more practical gloves. Additionally, the company possesses facilities such as screen printers and automatic packaging machines.
						Through rigorous quality control and a differentiated production approach, we provide high-quality products and services to both domestic and international markets, earning a good reputation from our customers.
						<br />
						<br />
						In August 2006, we obtained CE certification as well as ISO 9001 and ISO 14001 certifications, securing our competitive edge in global markets and establishing our status as an environmentally friendly company.
						Samhwa Clean Glove offers comprehensive solutions for industrial protective gloves and promises to be your best business partner through continuous research and innovation in technology, quality, and design, as well as through change management.
					</Box>
				</Box>

				<Box className={'right-section'}>
					<Box className={'title'}>Since 2003 until 2024, more than</Box>
					<Box className={'count-container'}>
						<CountUp
							className={'count'}
							end={10000000}
							duration={4}
						/>
						<Box className={'plus'}>+</Box>
					</Box>
					<Box className={'description'}>pairs of glove have been produced in SCGLOVE and exported all around world.</Box>
					<Box className={'description'}>Based on our experience and continuous efforts, we promise to provide better product quality at more competitive prices.</Box>
				</Box>
			</Box>
		);
	}
}

export default ContentThree;
