import React from 'react';
import './product.css';
import {
	Box,
	Divider,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from "@mui/material";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation, FreeMode, Thumbs} from "swiper/modules";
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import CircleIcon from '@mui/icons-material/Circle';
import Tooltip from "@mui/material/Tooltip";
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TabPanel from "../../components/common/tabPanel/TabPanel";
import WithLocation from "../../components/common/WithLocation";

interface Props {
	location: any;
}
interface State {
	thumbsSwiper: any;
	tabValue: number;
}

class Product extends React.PureComponent<Props, State> {
	state:State = {
		thumbsSwiper: undefined,
		tabValue: 1,
	}

	private setThumbsSwiper = (swiperInstance: any) => {
		this.setState({ thumbsSwiper: swiperInstance });
	};

	private handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		this.setState({ tabValue: newValue }, () => {});
	};

	renderDescription = () => {
		const {location} = this.props;
		return (
			<Box className={'description'} dangerouslySetInnerHTML={{ __html: location.state.data.mainDescription }}>
				{/*{location.state.data.mainDescription}*/}
			</Box>
		)
	}

	renderSizeTable = () => {
		const {location} = this.props;
		return (
			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell sx={{fontWeight: 'bold'}}>Size</TableCell>
							<TableCell sx={{fontWeight: 'bold'}} align="left">Number</TableCell>
							<TableCell sx={{fontWeight: 'bold'}} align="left">Length</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{location.state.data.size.map((i: any, index: number) => {
							return (
								<TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 }}}>
									<TableCell sx={{fontWeight: 'bold'}} align="left">{i.name.toUpperCase()}</TableCell>
									<TableCell align="left">{i.number}</TableCell>
									<TableCell align="left">{i.length}cm</TableCell>
								</TableRow>
							)
						})}
					</TableBody>
				</Table>
			</TableContainer>
		)
	}

	renderDataTable = () => {
		const {location} = this.props;
		return (
			<TableContainer component={Paper}>
				<Table>
					<TableBody>
						{Object.entries(location.state.data.technicalData).map(([key, value]: [key: any, value: any], index: number) => {
							return (
								<TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 }}}>
									<TableCell sx={{fontWeight: 'bold'}} align="left">{key.toUpperCase()}</TableCell>
									<TableCell align="left">{value}</TableCell>
								</TableRow>
							)
						})}
					</TableBody>
				</Table>
			</TableContainer>
		)
	}

	renderImage = (data: any) => {
		return data.map((i: any, index: number) => {
			return (
				<SwiperSlide className={'image'} key={index}>
					<img src={i} alt="" />
				</SwiperSlide>
			)
		})
	}

	render() {
		const {thumbsSwiper} = this.state;
		const {location} = this.props;
		return (
			<Box className={'product-container'}>
				<Box className={'top-wrapper'}>
					<Box className={'left'}>
						<Swiper
							style={{
								'--swiper-navigation-color': 'rgba(237,108,2,0.6)',
							}}
							loop={true}
							spaceBetween={10}
							navigation={true}
							thumbs={thumbsSwiper ? { swiper: thumbsSwiper } : undefined}
							modules={[FreeMode, Navigation, Thumbs]}
							className="mySwiper3"
						>
							{this.renderImage(this.props.location.state.data.imageList)}
						</Swiper>

						<Swiper
							onSwiper={this.setThumbsSwiper}
							loop={true}
							spaceBetween={10}
							slidesPerView={3}
							freeMode={true}
							watchSlidesProgress={true}
							modules={[FreeMode, Navigation, Thumbs]}
							className="mySwiper2"
						>
							{this.renderImage(this.props.location.state.data.imageList)}
						</Swiper>
					</Box>
					<Box className={'right'}>
						<Box className={'title'}>{location.state.data.gloveName}</Box>

						<Divider />

						<Box className={'content-container'}>
							<Box className={'text'}>Size:</Box>
							<Box className={'size-wrapper'}>
								{location.state.data.size.map((i: any, index: any) => {
									return (
										<Box className={'size'} key={index}>{i.name.toUpperCase()}</Box>
									)
								})}
							</Box>
						</Box>

						<Divider />

						<Box className={'content-container'}>
							<Box className={'text'}>Rubber yarn(wrist) color:</Box>
							{location.state.data.size.map((i: any, index: number) => {
								return (
									<Tooltip title={`${i.name.toUpperCase()}: ${i.rubberYarnColorName}`} key={index}>
										<IconButton>
											<CircleIcon sx={{color: i.rubberYarnColor}} />
										</IconButton>
									</Tooltip>
									)
							})}
						</Box>

						<Divider />

						<Box className={'content-container'}>
							<Box className={'text'}>Specification:</Box>
							<Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
								<Tabs
									value={this.state.tabValue}
									onChange={this.handleTabChange}
									variant="fullWidth"
									TabIndicatorProps={{sx: {backgroundColor: "#ed6c02"} }}
								>
									<Tab label="Description" value={1} />
									<Tab label="SIZE" value={2} />
									<Tab label="TECHNICAL DATA" value={3} />
								</Tabs>
							</Box>
							<TabPanel value={this.state.tabValue} index={1}>{this.renderDescription()}</TabPanel>
							<TabPanel value={this.state.tabValue} index={2}>{this.renderSizeTable()}</TabPanel>
							<TabPanel value={this.state.tabValue} index={3}>{this.renderDataTable()}</TabPanel>
						</Box>
					</Box>
				</Box>
			</Box>
		);
	}
}

export default WithLocation(Product);
