import React from 'react';
import './subFooter.css';
import {Box, IconButton} from "@mui/material";
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';

interface Props {}
interface State {}

class SubFooter extends React.PureComponent<Props, State> {
	render() {
		return (
			<Box className={'subFooter-container'}>
				<Box className={'copyright'}>© 2003 – 2024 Samwha Clean Glove. All rights reserved.</Box>
				<IconButton className={'to-top-icon-container'}>
					<Box className={'to-top-icon'}>
						<ArrowUpwardOutlinedIcon className={'icon'} sx={{color: 'white'}} />
						<Box className={'text'}>To Top</Box>
					</Box>
				</IconButton>
			</Box>
		);
	}
}

export default SubFooter;
