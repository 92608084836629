import React from 'react';
import './mainFooter.css';
import {Box, Divider} from "@mui/material";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import ApartmentIcon from '@mui/icons-material/Apartment';
import InfoWithIcon from "../../../common/infoWithIcon/InfoWithIcon";
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LOGO from "../../../../assets/images/SAMWHA_logo_03.gif";
import {EMAIL, TEL} from "../../../../constants/companyInfo";

interface Props {}
interface State {}

class MainFooter extends React.PureComponent<Props, State> {

	renderSection1 = () => {
		return (
			<Box className={'section section1'}>
				<Box sx={{display: 'flex'}}>
					<img className={'logo'} src={LOGO} alt={''} sizes={'cover'} />
				</Box>
				<Box className={'text'}>
					We are focused on user-driven outcomes, we strive to create meaningful connections for users through considered strategy and innovation.
				</Box>
			</Box>
		)
	}

	renderSection2 = () => {
		return (
			<Box className={'section section2'}>
				<Box className={'title'}>Business Hours</Box>
				<Box className={'content'}>
					<Box className={'text date'}>Monday-Friday:</Box>
					<Box className={'text time'}>8:30am to 5:30pm</Box>
				</Box>
				<Divider color={'#aaa'} />
				<Box className={'content'}>
					<Box className={'text date'}>Saturday:</Box>
					<Box className={'text time'}>8:30am to 3:30pm</Box>
				</Box>
				<Divider color={'#aaa'} />
				<Box className={'content'}>
					<Box className={'text date'}>Sunday:</Box>
					<Box className={'text time'}>Closed</Box>
				</Box>
				<Divider color={'#aaa'} />
			</Box>
		)
	}

	renderSection3 = () => {
		return (
			<Box className={'section section3'}>
				<Box className={'title'}>Quick Info</Box>
				<Box className={'content'}>
					<InfoWithIcon tooltip={'Tel'} icon={<LocalPhoneIcon />} color={'#cccccc'} />
					<Box className={'text time'}>{TEL}</Box>
				</Box>
				<Divider color={'#aaa'} />
				<Box className={'content'}>
					<InfoWithIcon tooltip={'Address'} icon={<ApartmentIcon />} color={'#cccccc'} />
					<Box className={'text time'}>429, Munsan-ro, Geumgok-myeon, Jinju-si, Gyeongsangnam-do, Republic of Korea</Box>
				</Box>
				<Divider color={'#aaa'} />
				{/*<Box className={'content'}>*/}
				{/*	<InfoWithIcon tooltip={'Fax'} icon={<FaxIcon />} />*/}
				{/*	<Box className={'text time'}>055-993-9050</Box>*/}
				{/*</Box>*/}
				<Divider color={'#aaa'} />
				<Box className={'content'}>
					<InfoWithIcon tooltip={'Email'} icon={<AlternateEmailIcon />} color={'#cccccc'} />
					<Box className={'text time'}>{EMAIL}</Box>
				</Box>
			</Box>
		)
	}

	render() {
		return (
			<Box className={'mainFooter-container'}>
				<Box className={'footer-image'}>
					<Box className={'footer-image-cover'}></Box>
				</Box>
				<Box className={'contents'}>
					{this.renderSection1()}
					{this.renderSection2()}
					{this.renderSection3()}
				</Box>
			</Box>
		);
	}
}

export default MainFooter;
