import React, {createRef, CSSProperties, RefObject} from 'react';
import './mainContainer.css';
import {Box} from "@mui/material";
import Header from "../header/Header";
import Footer from "../footer/Footer";

interface Props {
	children: React.ReactNode;
	contentStyle?: CSSProperties;
	footer?: React.ReactNode;
}
interface State {
	scrollY: number,
}

class MainContainer extends React.PureComponent<Props, State> {
	containerRef: RefObject<HTMLDivElement>;

	constructor(props: Props) {
		super(props);
		this.state = {
			scrollY: 0,
		};
		this.containerRef = createRef();
	}

	componentDidMount() {
		if (this.containerRef.current) {
			this.containerRef.current.addEventListener('scroll', this.handleScroll);
		}
	}

	componentWillUnmount() {
		if (this.containerRef.current) {
			this.containerRef.current.removeEventListener('scroll', this.handleScroll);
		}
	}

	private handleScroll = () => {
		if (this.containerRef.current) {
			this.setState({ scrollY: this.containerRef.current.scrollTop });
		}
	}

	render() {
		return (
			<Box className={'base-wrapper-root'} ref={this.containerRef} >
				<Header scrollY={this.state.scrollY} />
				<Box
					className={'base-wrapper-content'}
					style={this.props.contentStyle}>
					{this.props.children}
				</Box>
				<Footer />
			</Box>
		);
	}
}

export default MainContainer;
