import React from 'react';
import './products.css';
import {Box, Card, CardActionArea} from "@mui/material";
import {gloveData} from "../../database/gloveData";
import { useNavigate } from 'react-router-dom';

interface Props {}
interface State {}

class Products extends React.PureComponent<Props, State> {
	renderCategoryList = () => {
		return gloveData.map((data) => {
			return (
				<Card
					key={data.id}
					elevation={6}
					className={'category'}
				>
						<NavigateToProduct data={data} />
				</Card>
			)
		})
	}

	render() {
		return (
			<Box className={'products-container'}>
				<Box className={'header'}>Select glove type</Box>
				<Box className={'description'}>Please select one of the yarn type for the gloves you are looking for</Box>
				<Box className={'category-container'}>
					{this.renderCategoryList()}
				</Box>
			</Box>
		);
	}
}

export default Products;


const NavigateToProduct = ({ data }: {data: any}) => {
	const navigate = useNavigate();

	const handleClick = () => {
		navigate(
			`/product/${data.id}`,
			{ state: { category: data.category, id: data.id } });
	};

	return (
		<CardActionArea disabled={!data.enable} className={'category-action'} onClick={handleClick}>
			{data.category}
		</CardActionArea>
	);
};

