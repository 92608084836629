import React from 'react';
import './infoWithIcon.css';
import Tooltip from "@mui/material/Tooltip";
import {IconButton} from "@mui/material";
import Box from "@mui/material/Box";

interface Props {
	tooltip: string;
	icon: React.ReactNode;
	color?: string;
	children?: React.ReactNode;
}
interface State {}

class InfoWithIcon extends React.PureComponent<Props, State> {
	render() {
		const {tooltip, icon, children} = this.props;
		return (
			<Box className={'info-item-container'} style={{}}>
				<Tooltip title={tooltip} arrow placement="left">
					<IconButton sx={{ color: this.props.color || 'white' }}>{icon}</IconButton>
				</Tooltip>
				{children}
			</Box>
		);
	}
}

export default InfoWithIcon;
