import React from 'react';
import './contentFive.css';
import {Box, Card, Divider} from "@mui/material";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import {EMAIL, TEL} from "../../../../constants/companyInfo";

interface Props {}
interface State {}

interface IContactData {
	id: number;
	icon: React.ReactNode;
	title: string;
	detail: React.ReactNode;
}

const contactData: IContactData[] = [
	{
		id: 0,
		icon: <LocationOnIcon className={'icon'} />,
		title: 'ADDRESS',
		detail:
			<>
				429, Munsan-ro, Geumgok-myeon, Jinju-si,
				<br />
				Gyeongsangnam-do,
				<br />
				Republic of Korea
			</>
		,
	},
	{
		id: 1,
		icon: <LocalPhoneIcon className={'icon'} />,
		title: 'PHONE',
		detail: TEL,
	},
	{
		id: 2,
		icon: <AlternateEmailIcon className={'icon'} />,
		title: 'EMAIL',
		detail: EMAIL,
	},
]

class ContentFive extends React.PureComponent<Props, State> {

	renderContactData = () => {
		return contactData.map((i: IContactData) => {
			return (
				<Card className={'info'} elevation={8} key={i.id}>
					<Box className={'icon-container'}>
						<Box className={'icon-wrapper'}>{i.icon}</Box>
						<Box className={'title'}>{i.title}</Box>
					</Box>
					<Divider sx={{width: '100%'}} />
					<Box className={'text'}>{i.detail}</Box>
				</Card>
			)
		})
	}

	render() {
		return (
			<Box className={'content-five-container'}>
				<Box className={'wrapper'}>
					<Box className={'shape'}></Box>
					<Box className={'header'}>GET IN TOUCH</Box>
					<Box className={'info-container'}>
						{this.renderContactData()}
					</Box>
				</Box>
			</Box>
		);
	}
}

export default ContentFive;
