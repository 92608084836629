import React from 'react';
import './contact.css';
import {Box, Card} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import {EMAIL, TEL, WEB} from "../../../constants/companyInfo";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import LanguageIcon from '@mui/icons-material/Language';

interface Props {}
interface State {}

interface IContactData {
	id: number;
	icon: React.ReactNode;
	title: string;
	detail: React.ReactNode;
}

const contactData: IContactData[] = [
	{
		id: 0,
		icon: <AlternateEmailIcon className={'icon'} />,
		title: 'EMAIL',
		detail: EMAIL,
	},
	{
		id: 1,
		icon: <LocationOnIcon className={'icon'} />,
		title: 'ADDRESS',
		detail:
			<>
				429, Munsan-ro, Geumgok-myeon, Jinju-si,
				<br />
				Gyeongsangnam-do,
				<br />
				Republic of Korea
			</>
		,
	},
	{
		id: 2,
		icon: <LocalPhoneIcon className={'icon'} />,
		title: 'PHONE',
		detail: TEL,
	},
	{
		id: 3,
		icon: <LanguageIcon className={'icon'} />,
		title: 'WEBSITE',
		detail: WEB,
	},
]

class Contact extends React.PureComponent<Props, State> {

	private renderContactItem = () => {
		return contactData.map((i: IContactData) => {
			return (
				<Card className={'item-wrapper'} elevation={2} key={i.id}>
					<Box className={'icon-container'}>
						<Box className={'icon-wrapper'}>{i.icon}</Box>
					</Box>
					<Box className={'text'}>{i.detail}</Box>
				</Card>
			)
		})
	}

	render() {
		return (
			<Box className={'contact-container'}>
				{/*<Box className={'title'}>Contact</Box>*/}
				<Box className={'content-wrapper'}>
					<Box className={'left'}>
						<Box className={'header'}>Contact</Box>
						<Box className={'description'}>In addition to the products listed under the "Products" menu, we are capable of manufacturing a wide range of custom products. We can tailor various yarns, coating types, and packaging processes to meet consumer demands. Please feel free to contact us without hesitation.</Box>
						<Box className={'item-container'}>
							{this.renderContactItem()}
						</Box>
					</Box>
					<Box className={'right'}>
						<iframe
							className={'map'}
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d626.4850027110664!2d128.22084100857455!3d35.13244630271823!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x356ee31c318146c3%3A0x5a764a106ae33723!2z7IK87ZmU7YGs66aw6riA65-s67iM!5e0!3m2!1sen!2skr!4v1718621950413!5m2!1sen!2skr"
							loading="lazy"
							frameBorder={0}
							referrerPolicy="no-referrer-when-downgrade">
						</iframe>
					</Box>
				</Box>
			</Box>
		);
	}
}

export default Contact;
