import React from 'react';
import './footer.css';
import {Box} from "@mui/material";
import SubFooter from "./subFooter/SubFooter";
import MainFooter from "./mainFooter/MainFooter";

interface Props {}
interface State {}

class Footer extends React.PureComponent<Props, State> {
	render() {
		return (
		 <Box className={'footer'}>
			 <MainFooter />
			 <SubFooter />
		 </Box>
		);
	}
}

export default Footer;
