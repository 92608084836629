import React from 'react';
import './contentTwo.css';
import {Box, Card} from "@mui/material";

interface Props {}
interface State {}

interface IData {
	id: string;
	title: string;
	description: string;
}

const contentTwoData: IData[] = [
	{
		id: '01',
		title: 'Honesty',
		description: 'Be humble in all dealings with our partners, clients and team members. True wisdom and understanding belong to the humble.'
	},
	{
		id: '02',
		title: 'Passion',
		description: 'Success is when we can achieve results in the things we are passionate about and feel as though we are making a difference.'
	},
	{
		id: '03',
		title: 'Quality Work',
		description: 'We ensure that all projects are done with utmost professionalism using quality materials while offering clients the support and accessibility.'
	}
]

class ContentTwo extends React.PureComponent<Props, State> {

	renderCardItem = (data: IData[]) => {
		return data.map((i: IData) => {
			return (
				<Card className={'card'} key={i.id}>
					<Box className={'number'}>{i.id}</Box>
					<Box className={'text-container'}>
						<Box className={'title'}>{i.title}</Box>
						<Box className={'description'}>{i.description}</Box>
					</Box>
				</Card>
			)
		})
	}

	render() {
		return (
			<Box sx={{}} className={'content-two-container'}>
				{this.renderCardItem(contentTwoData)}
			</Box>
		);
	}
}

export default ContentTwo;
