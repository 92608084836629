import React from 'react';
import './productList.css';
import {Box, Card, CardActionArea, CardContent, CardMedia, Divider, Grid, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";

interface Props {
	filteredData: any;
}
interface State {
	data: any;
}

class ProductList extends React.PureComponent<Props, State> {

	render() {
		return (
			<Box sx={{ flexGrow: 1 }}>
				{/*<Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 12, md: 16 }}>*/}
				<Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 12 }}>

					{this.props.filteredData.map((i: any, index: number) => (
						<Grid item xs={2} sm={4} md={4} key={index}>
							<Card className={'card'} elevation={6} >
								<NavigateToProduct data={i}>
									<CardMedia
										component="img"
										height="100%"
										image={i.image}
										sizes={'contain'}
										alt=""
										style={{padding: '20px 30px'}}
									/>
									<Divider />
									<CardContent sx={{padding: '20px 30px'}}>
										<Typography gutterBottom variant="h5" component="div">
											{i.gloveName}
										</Typography>
										<Typography variant="body2" color="text.secondary">
											{i.shortDescription}
										</Typography>
									</CardContent>
								</ NavigateToProduct>
							</Card>
						</Grid>
					))}
				</Grid>
			</Box>
		);
	}
}

export default ProductList;

const NavigateToProduct = ({ data, children }: {data: any, children: any}) => {
	const navigate = useNavigate();

	const handleClick = () => {
		navigate(
			`/product/item/${data.id}`,
			{ state: { data: data} });
	};

	return (
		<CardActionArea className={'category-action'} onClick={handleClick}>
			{children}
		</CardActionArea>
	);
};
