import React from 'react';
import './productHeader.css';
import {Box, Breadcrumbs, Typography} from "@mui/material";
import {NavLink} from "react-router-dom";

interface Props {
	category: string;
}
interface State {}

class ProductHeader extends React.PureComponent<Props, State> {
	render() {
		const {category} = this.props;
		return (
			<Box className={'product-header-container'}>
				<Box className={'title'}>
					{category}
				</Box>
				<Breadcrumbs aria-label="breadcrumb" className={'breadcrumb'}>
					<Box component={NavLink} to={'/'} className={'menu'}>
						HOME
					</Box>
					<Box component={NavLink} to={'/product'} className={'menu'}>
						PRODUCTS
					</Box>
					<Typography color="text.primary">
						{category}
					</Typography>
				</Breadcrumbs>
			</Box>
		);
	}
}

export default ProductHeader;
